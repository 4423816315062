(function(window) {

	var CosimoNapoli = function() {

		var enableSlider = function () {
			let items = document.querySelectorAll('.cosimo-carousel .carousel-item')

			items.forEach((el) => {
				const minPerSlide = 4;
				let next = el.nextElementSibling;
				for (var i = 1; i < minPerSlide; i++) {
					if (!next) {
						// wrap carousel by using first child
						next = items[0];
					}
					let cloneChild = next.cloneNode(true);
					el.appendChild(cloneChild.children[0]);
					next = next.nextElementSibling;
				}
			});

			$('#cosimo-community-slider .carousel-item img').click(function () {
				var slideIndex = $(this)[0].getAttribute('data-bs-slide-to');
				$('#modal-slider').carousel(parseInt(slideIndex));
			});
		};

		this.enableSlider  = enableSlider;

	};

	/* -------------------------------------------------------------------------- */

	$(document).ready(function () {
		var cosimoNapoli = new CosimoNapoli();
		cosimoNapoli.enableSlider();
		window.cosimoNapoli = cosimoNapoli;
		$(window).trigger('resize');
		$(window).trigger('scroll');
	});

})(window);